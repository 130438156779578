import React, { useEffect, useRef } from 'react';

import { Burger } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBrandWhatsapp } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import MHeaderLine from './MHeaderLine';

const MHeader = () => {
    const navigate = useNavigate();

    const [opened, { toggle }] = useDisclosure(false);

    const phoneMenuListRef = useRef<HTMLDivElement>(null);
    const burgerIconRef = useRef<HTMLButtonElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        burgerIconRef.current?.addEventListener('click', () => {
            opened
                ? (phoneMenuListRef.current!.style.transform = 'translate(0, -120%)')
                : (phoneMenuListRef.current!.style.transform = 'translate(0, 0)');
        });
    }, [opened]);

    useEffect(() => {
        window.scrollY &&
            window.addEventListener('scroll', () => {
                if (window.scrollY > 100) headerRef.current!.style.backgroundColor = 'rgb(255,255,255)';
                else headerRef.current!.style.backgroundColor = 'rgba(255,255,255,0)';
            });
    }, []);

    const menuOnclick = (link: string) => {
        let menuHeight = document.querySelector<HTMLDivElement>('#header')!.offsetHeight;

        var location = document.querySelector<HTMLDivElement>(link)!.offsetTop;

        window.scrollY &&
            window.scrollTo({
                top: location - menuHeight,
                behavior: 'smooth',
            });
    };

    const phoneMenuOnclick = (link: string) => {
        let menuHeight = document.querySelector<HTMLDivElement>('#header')!.offsetHeight;

        var location = document.querySelector<HTMLDivElement>(link)!.offsetTop;

        window.scrollY &&
            window.scrollTo({
                top: location - menuHeight,
                behavior: 'smooth',
            });

        toggle();
        phoneMenuListRef.current!.style.transform = 'translate(0, -100%)';
    };

    return (
        <header className="fixed left-0 top-0 w-full h-fit max-w-[100vw] bg-white z-40">
            <div
                id="header-line"
                className="hidden xl:block relative w-full h-fit py-1 px-[calc((100%-var(--container))/2)] bg-gradient-to-t to-[var(--light-color)] from-[var(--main-color)] text-white "
            >
                <div className=" relative w-full h-fit max-w-[var(--container-width)] px-10 mx-auto flex justify-between items-center gap-2 2xl:gap-5">
                    <p className="text-[#fff700] hidden 2xl:block">처음처럼 모두치움의 장점 4가지</p>
                    <p className="hidden 2xl:block">|</p>
                    <p>①&nbsp;출장비&nbsp;없는&nbsp;출장견적</p>
                    <p>②&nbsp;고객만족&nbsp;120%&nbsp;달성을&nbsp;위한&nbsp;성실함</p>
                    <p>③&nbsp;다양한&nbsp;경험!&nbsp;다양한&nbsp;작업사례</p>
                    <p>④&nbsp;365일&nbsp;24시간&nbsp;상담가능!</p>
                    <p className="text-[#fff700] xl:text-xl">지금&nbsp;바로&nbsp;전화주세요!</p>
                </div>
            </div>
            <div
                ref={headerRef}
                id="header"
                className="relative w-full h-fit px-[calc((100%-var(--container))/2)] max-h-20 lg:max-h-32 py-2 overflow-hidden z-40 transition-all duration-300"
            >
                <div className="relative w-full h-full max-w-[var(--container-width)] mx-auto flex justify-between items-center gap-6 xl:gap-16 2xl:gap-20">
                    <Burger
                        ref={burgerIconRef}
                        opened={opened}
                        onClick={toggle}
                        className="block lg:hidden"
                        size="md"
                        color="blue"
                    />
                    <div className="relative w-full h-fit max-w-52 xl:max-w-80">
                        <img
                            alt="logo"
                            onClick={() => navigate('/')}
                            className="!relative w-full h-auto max-h-20 lg:max-h-none py-3 object-contain cursor-pointer"
                            src="/images/logo.png"
                            width={429}
                            height={153}
                        />
                    </div>
                    <div className="block lg:hidden relative w-fit h-fit p-1 mx-1 rounded-full">
                        <IconBrandWhatsapp
                            onClick={() => {
                                window.location.href = 'tel:' + process.env.NEXT_PUBLIC_NUMBER;
                            }}
                            size={30}
                            stroke={1}
                            className="text-main"
                        />
                    </div>
                    <ul className="hidden relative w-fit h-fit text-center lg:flex justify-around items-center justify-items-center gap-5 2xl:gap-14 cursor-pointer">
                        <li onClick={() => menuOnclick('#about')}>
                            <p>처음처럼&nbsp;모두치움</p>
                        </li>
                        <li onClick={() => menuOnclick('#service')}>
                            <p>서비스&nbsp;목록</p>
                        </li>
                        <li onClick={() => menuOnclick('#blog')}>
                            <p>BLOG&nbsp;작업사례</p>
                        </li>
                        <li onClick={() => menuOnclick('#list')}>
                            <p>실시간&nbsp;견적현황</p>
                        </li>
                        <li onClick={() => menuOnclick('#contact')}>
                            <p>견적문의</p>
                        </li>
                    </ul>
                    <div className="hidden lg:block relative w-full h-fit max-w-80">
                        <img
                            onClick={() => {
                                window.location.href = 'tel:' + process.env.NEXT_PUBLIC_NUMBER;
                            }}
                            className="!relative w-full h-auto min-w-56 object-contain cursor-pointer"
                            alt="button"
                            src="/images/number.png"
                            width={532}
                            height={132}
                        />
                    </div>
                </div>
            </div>
            <MHeaderLine />
            <div
                ref={phoneMenuListRef}
                className="block lg:hidden fixed top-[60px] left-0 w-full h-full -translate-y-full transition-transform duration-500 z-30"
            >
                <ul className="border-b bg-white text-font">
                    <li onClick={() => phoneMenuOnclick('#about')} className="border-b py-4 text-center">
                        <p className="text-base">처음처럼&nbsp;모두치움</p>
                    </li>
                    <li onClick={() => phoneMenuOnclick('#service')} className="border-b py-4 text-center">
                        <p className="text-base">서비스&nbsp;목록</p>
                    </li>
                    <li onClick={() => phoneMenuOnclick('#blog')} className="border-b py-4 text-center">
                        <p className="text-base">BLOG&nbsp;작업사례</p>
                    </li>
                    <li onClick={() => menuOnclick('#list')} className="border-b py-4 text-center">
                        <p className="text-base">실시간&nbsp;견적현황</p>
                    </li>
                    <li onClick={() => phoneMenuOnclick('#contact')} className="border-b py-4 text-center">
                        <p className="text-base">견적문의</p>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default MHeader;
