import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@mantine/core/styles.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';

import Layout from 'components/common/Layout';
import Home from 'page/home';
import Transform from 'page/transform';
import AdminLogin from 'page/login';
import Dashboard from 'page/admin';
import AContact from 'page/admin/contact';
import AOneContact from 'page/admin/contact/detail';

function App() {
    function vh_height() {
        var vh = window.outerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        vh_height();
        AOS.init({
            duration: 800,
        });
    }, []);

    const referrer = window.document.referrer;

    if (!referrer.includes('https://xn--hu1bq3ewwc43s5tah8wba107a.com')) {
        sessionStorage.setItem('path', referrer);
    }

    console.log(referrer);
    console.log(window.navigator.userAgent);

    return (
        <div className="App">
            <ToastContainer position="top-center" autoClose={3000} />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/transform" element={<Transform />} />
                    <Route path="admin">
                        <Route path="login" element={<AdminLogin />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="inquiry" element={<AContact />} />
                        <Route path="inquiry/:id" element={<AOneContact />} />
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
