import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const Banner = () => {
    return (
        <div className="relative w-full h-full mt-24 lg:mt-32 2xl:mt-40">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                loop={true}
                navigation={true}
                modules={[Autoplay]}
                className="mySwiper h-full"
            >
                <SwiperSlide>
                    <div className=" relative w-full h-full min-h-[80vh] lg:h-[75vh] 2xl:h-[700px] grid lg:grid-cols-2 2xl:grid-cols-5">
                        <div className="2xl:col-span-3 relative w-full h-full">
                            <img
                                className=" relative w-full h-full object-cover object-center"
                                src="/images/banner1.png"
                                alt="banner"
                            />
                        </div>
                        <div className="2xl:col-span-2 relative w-full h-full min-h-[400px]">
                            <div className=" relative w-full h-fit lg:h-full text-center lg:text-left lg:pl-10 pt-10 lg:pt-14">
                                <h1 className="manru font-normal leading-tight">
                                    <span className="text-[var(--point-color)] manru font-normal">서울·경기·인천</span>
                                    &nbsp;전지역
                                </h1>
                                <h1 className="manru font-normal text-[var(--point-color)] leading-tight">
                                    출장비 없는 출장견적!
                                </h1>
                                <h4 className=" font-normal mt-3">
                                    고객님의 상황에 맞춘 비대면 사진견적도 가능합니다!
                                </h4>
                            </div>
                            <div className="relative lg:absolute bottom-0 left-0 lg:left-10 w-full h-fit pt-12 lg:pt-0">
                                <img
                                    className=" relative w-full h-auto max-w-96 mx-auto lg:mx-0 2xl:max-w-screen-sm object-contain"
                                    src="/images/icon_main.png"
                                    alt="icon"
                                />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" relative w-full h-full min-h-[80vh] lg:h-[75vh] 2xl:h-[700px] grid lg:grid-cols-2 2xl:grid-cols-5 bg-[var(--light-color)]">
                        <div className="2xl:col-span-3 relative w-full h-full">
                            <img
                                className=" relative w-full h-full object-cover object-center"
                                src="/images/banner2.png"
                                alt="banner"
                            />
                        </div>
                        <div className="2xl:col-span-2 relative w-full h-full min-h-[400px]">
                            <div className=" relative w-full h-fit lg:h-full text-center lg:text-left lg:pl-10 pt-10 2xl:pt-14 px-5 lg:pr-0">
                                <div className=" relative w-full h-fit lg:max-w-screen-sm">
                                    <img
                                        className="hidden lg:block relative w-full h-full object-contain"
                                        src="/images/banner_txt.png"
                                        alt="text"
                                    />
                                    <img
                                        className="block lg:hidden relative w-full h-full max-w-screen-sm lg:max-w-none mx-auto lg:mx-0 object-contain"
                                        src="/images/banner_txt_mo.png"
                                        alt="text"
                                    />
                                </div>
                                <div className="mt-5 relative w-full h-fit max-w-screen-sm flex justify-start items-center gap-2 text-center mx-auto lg:mx-0">
                                    <h5 className=" relative w-full h-fit bg-white text-[var(--light-color)] leading-none py-1 rounded-sm">
                                        유품정리
                                    </h5>
                                    <h5 className=" relative w-full h-fit bg-white text-[var(--light-color)] leading-none py-1 rounded-sm">
                                        쓰레기집
                                    </h5>
                                    <h5 className=" relative w-full h-fit bg-white text-[var(--light-color)] leading-none py-1 rounded-sm">
                                        빈집정리
                                    </h5>
                                    <h5 className=" relative w-full h-fit bg-white text-[var(--light-color)] leading-none py-1 rounded-sm">
                                        특수청소
                                    </h5>
                                    <h5 className=" relative w-full h-fit bg-white text-[var(--light-color)] leading-none py-1 rounded-sm">
                                        고독사정리
                                    </h5>
                                </div>
                                <h5 className=" opacity-0 sm:hidden relative w-full h-fit bg-white text-[var(--light-color)] leading-none py-1 rounded-sm">
                                    고독사정리
                                </h5>
                            </div>
                            <div className="relative lg:absolute bottom-0 left-0 lg:left-10 w-full h-fit pt-12 lg:pt-0">
                                <img
                                    className=" relative w-full h-auto max-w-96 mx-auto lg:mx-0 2xl:max-w-screen-sm object-contain"
                                    src="/images/icon_main.png"
                                    alt="icon"
                                />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" relative w-full h-full min-h-[80vh] lg:h-[75vh] 2xl:h-[700px] grid lg:grid-cols-2 2xl:grid-cols-5 ">
                        <div className="2xl:col-span-3 relative w-full h-full">
                            <img
                                className=" relative w-full h-full object-cover object-center"
                                src="/images/banner3.png"
                                alt="banner"
                            />
                        </div>
                        <div className="2xl:col-span-2 relative w-full h-full min-h-[400px]">
                            <div className=" relative w-full h-fit lg:h-full text-center lg:text-left lg:pl-10 pt-10 2xl:pt-14">
                                <h1 className="leading-tight">
                                    <span className="manru leading-tight text-[var(--main-color)]">당일문의!</span>
                                    &nbsp;
                                    <span className="manru leading-tight">당일작업!</span>
                                </h1>
                                <h1 className="leading-tight">
                                    <span className="manru leading-tight text-[#fd9f28]">비밀보장!</span>
                                    &nbsp;
                                    <span className="manru leading-tight text-[var(--main-color)]">고객만족!</span>
                                </h1>
                                <h4 className=" font-normal mt-3">
                                    후회하시지&nbsp;않도록&nbsp;최선을&nbsp;다하겠습니다! 지금&nbsp;바로&nbsp;문의주세요
                                </h4>
                            </div>
                            <div className="relative lg:absolute bottom-0 left-0 lg:left-10 w-full h-fit pt-12 lg:pt-0">
                                <img
                                    className=" relative w-full h-auto max-w-96 mx-auto lg:mx-0 2xl:max-w-screen-sm object-contain"
                                    src="/images/icon_main.png"
                                    alt="icon"
                                />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Banner;
