import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useState } from 'react';
import { contact } from 'api/contact';

const inquiries = [
    {
        createdAt: '08:38:26',
        name: '안혜진',
        type: '유품정리',
        category: '긴급',
        completeYn: 'Y',
    },
    {
        createdAt: '08:50:57',
        name: '이영천',
        type: '가전수거',
        category: '일반',
        completeYn: 'Y',
    },
    {
        createdAt: '10:24:15',
        name: '김리나',
        type: '빈집정리',
        category: '일반',
        completeYn: 'Y',
    },
    {
        createdAt: '12:15:20',
        name: '전리나',
        type: '빈집정리',
        category: '긴급',
        completeYn: 'N',
    },
    {
        createdAt: '13:21:49',
        name: '박리나',
        type: '쓰레기집',
        category: '긴급',
        completeYn: 'N',
    },
    {
        createdAt: '13:52:15',
        name: '김리나',
        type: '유품정리',
        category: '긴급',
        completeYn: 'N',
    },
    {
        createdAt: '20:14:19',
        name: '김리나',
        type: '가구수거',
        category: '긴급',
        completeYn: 'N',
    },
    {
        createdAt: '08:50:57',
        name: '이영천',
        type: '가전수거',
        category: '일반',
        completeYn: 'Y',
    },
    {
        createdAt: '10:24:15',
        name: '김리나',
        type: '빈집정리',
        category: '일반',
        completeYn: 'Y',
    },
    {
        createdAt: '21:26:43',
        name: '정리나',
        type: '폐기물처리',
        category: '일반',
        completeYn: 'N',
    },
    {
        createdAt: '21:26:43',
        name: '정리나',
        type: '가전수거',
        category: '일반',
        completeYn: 'N',
    },
    {
        createdAt: '21:26:43',
        name: '정리나',
        type: '폐기물처리',
        category: '일반',
        completeYn: 'N',
    },
    {
        createdAt: '12:15:20',
        name: '전리나',
        type: '빈집정리',
        category: '긴급',
        completeYn: 'N',
    },
    {
        createdAt: '13:21:49',
        name: '박리나',
        type: '쓰레기집',
        category: '긴급',
        completeYn: 'N',
    },
];

const InquiryList = () => {
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [service, setService] = useState<string>('');
    const [contents, setContents] = useState<string>('');
    const categoryId = 2;

    const [loading, setLoading] = useState(false);

    return (
        <div id="list" className="relative w-full h-full wrap py-14 lg:py-24 z-0">
            <div id="title" className="relative w-full h-fit text-center mb-5 lg:mb-10">
                <div className="md:flex justify-center items-center gap-2 mb-2">
                    <h2 className="leading-none sebang">실시간 접수현황</h2>
                </div>
                <p className=" lg:text-xl">오늘도 처음처럼 모두치움을 이용해주셔서 감사합니다.</p>
            </div>
            <div className="relative w-full h-fit max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-0">
                <div className=" relative w-full h-full">
                    <InquiryCard />
                </div>
                <div className=" relative w-full h-full">
                    <div className=" relative w-full h-fit bg-[#0b7af8] py-1.5 rounded-t-xl">
                        <img
                            src="/images/white_logo.png"
                            alt="logo"
                            className="relative w-full h-auto max-w-96 mx-auto"
                        />
                    </div>
                    <div className="relative w-full grid gap-3 pt-5 px-3 lg:p-5">
                        <div className="relative w-full grid grid-cols-2 gap-5">
                            <div>
                                <label htmlFor="name2">이름</label>
                                <input
                                    type="text"
                                    id="name2"
                                    name="name2"
                                    placeholder="이름을 입력해주세요"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="phone2">연락처</label>
                                <input
                                    type="text"
                                    id="phone2"
                                    name="phone2"
                                    placeholder="연락처를 입력해주세요"
                                    onChange={(e) =>
                                        setPhone(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="address2">주소</label>
                            <input
                                type="text"
                                id="address2"
                                name="address2"
                                placeholder="주소를 입력해주세요"
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="contents2">문의 내용</label>
                            <textarea
                                id="contents2"
                                name="contents2"
                                className="!h-24"
                                placeholder="내용을 입력해주세요"
                                onChange={(e) => setContents(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <div>
                                <span className="flex items-center">
                                    <input type="checkbox" name="privacy3" id="privacy3" defaultChecked readOnly />
                                    &nbsp;
                                    <label htmlFor="privacy3" className="text-xs">
                                        [필수]&nbsp;개인정보&nbsp;수집&nbsp;이용&nbsp;제공&nbsp;동의
                                    </label>
                                </span>
                                <span className="flex items-center">
                                    <input type="checkbox" name="privacy4" id="privacy4" defaultChecked readOnly />
                                    &nbsp;
                                    <label htmlFor="privacy4" className="text-xs">
                                        [필수]&nbsp;이름,&nbsp;연락처,&nbsp;주소에&nbsp;대한&nbsp;정보를
                                        모두&nbsp;확인하였습니다.
                                    </label>
                                </span>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    disabled={loading}
                                    onClick={() => {
                                        setLoading(true);
                                        contact({
                                            name,
                                            phone,
                                            address,
                                            service,
                                            contents,
                                            categoryId,
                                        });
                                    }}
                                    className=" relative w-fit h-full py-1 px-8 bg-gradient-to-t to-[var(--light-color)] from-[var(--main-color)] text-white"
                                >
                                    <h5 className="font-bold">전송하기</h5>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-2 relative w-full h-fit">
                    <img src="/images/bottom.png" alt="number" />
                </div>
            </div>
        </div>
    );
};

function InquiryCard() {
    let slidesviews = 9;
    return (
        <div className="relative w-full h-full px-3 lg:px-0 max-h-80 lg:max-h-[490px] rounded-t-xl overflow-hidden">
            <Swiper
                direction={'vertical'}
                centeredSlides={true}
                loop={true} // loop 여부
                // loopedSlides={3}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                slidesPerView={slidesviews}
                className="mySwiper relative w-full h-full pt-8"
            >
                {inquiries.map((v, i) => (
                    <SwiperSlide key={i}>
                        <div
                            className={
                                i % 2 >= 1
                                    ? `relative w-full h-full grid grid-cols-5 items-center lg:gap-5 bg-gray-100 px-1 xl:px-5 lg:py-2`
                                    : `relative w-full h-full grid grid-cols-5 items-center lg:gap-5 px-1 xl:px-5 lg:py-2`
                            }
                        >
                            <p className="scale-75 lg:scale-95 opacity-70 text-base">{v.createdAt}</p>
                            <p className="text-center opacity-70 text-base scale-90 lg:scale-100">{v.type}</p>
                            <p className="text-center opacity-70 text-base scale-90 lg:scale-100">{v.category}</p>
                            <p className="text-center opacity-70 text-base scale-90 lg:scale-100">{v.name[0] + '00'}</p>

                            {v.completeYn === 'Y' ? (
                                <div className="relative w-full h-fit py-0.5 text-center rounded-full bg-[var(--main-color)] text-white">
                                    <p className="text-base scale-90 lg:scale-100">진행완료</p>
                                </div>
                            ) : (
                                <div className="relative w-full h-fit py-0.5 text-center rounded-full bg-[var(--light-color)] text-white">
                                    <p className="text-base scale-90 lg:scale-100">진행중</p>
                                </div>
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default InquiryList;
