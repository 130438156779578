import { dateFormat } from 'const/date';

const Blog = ({ data }: { data: any[] }) => {
    const blog = ParseData({ data });
    return (
        <div id="blog" className="relative w-full h-full my-14 lg:my-28 ">
            <img className="hidden lg:block" alt="background" src="/images/blogBg.png" width={2558} height={1273} />
            <img
                className="block lg:hidden mx-auto"
                alt="background"
                src="/images/blogBgMo.png"
                width={800}
                height={1200}
            />
            <div className="absolute left-0 top-0 w-full h-full px-[calc((100%-var(--container))/2)] ">
                <div className="relative w-full h-fit max-w-[var(--container-width)] mx-auto py-7 xl:py-14">
                    <div className="relative w-full h-fit flex flex-wrap lg:flex-nowrap justify-center lg:justify-start gap-3 xl:gap-10 items-start">
                        <div className="relative w-60 md:w-80 lg:w-[400px] lg:mb-0 h-fit mx-auto md:mx-0">
                            <img
                                className="!relative w-full h-auto object-contain"
                                alt="logo"
                                src="/images/logo.png"
                                width={454}
                                height={89}
                            />
                        </div>
                        <div className="text-center lg:text-left">
                            <p className=" lg:text-2xl text-light">
                                정리!&nbsp;누구나&nbsp;할&nbsp;수&nbsp;있어보이지만,&nbsp;아무나&nbsp;할&nbsp;수는&nbsp;없습니다!
                                많은&nbsp;사람들이&nbsp;거쳐간&nbsp;처음처럼&nbsp;모두치움!
                                그&nbsp;생생한&nbsp;현장을&nbsp;보여드리겠습니다.
                            </p>
                        </div>
                    </div>
                    <div className="lg:grid grid-cols-4 mt-5 xl:mt-14">
                        <div></div>
                        <div className="lg:col-span-3 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                            {blog &&
                                blog.map((v: any, i: number) => (
                                    <div
                                        key={i}
                                        onClick={() => window.open(v.link[0])}
                                        className={
                                            i > 5 ? 'relative w-full h-full hidden md:block' : 'relative w-full h-full'
                                        }
                                    >
                                        <div className="relative w-full h-full grid bg-white p-2 md:p-3 rounded-3xl text-left border hover:border-[var(--main-color)] cursor-pointer transition-all duration-300">
                                            <p
                                                className={`relative w-full whitespace-nowrap overflow-hidden overflow-ellipsis lg:text-lg h-fit`}
                                            >
                                                {v.title[0]}
                                            </p>
                                            <small
                                                className={`relative w-full whitespace-nowrap md:whitespace-normal overflow-hidden overflow-ellipsis h-fit md:h-9 lg:h-10 break-words md:line-clamp-2 text-sm opacity-60`}
                                            >
                                                {v.description[0]}
                                            </small>
                                            <div className="md:pt-2 lg:flex justify-between items-end">
                                                <small className="hidden xl:block opacity-40 lg:text-sm">
                                                    {dateFormat(v.pubDate[0])}
                                                </small>
                                                <small className="text-main lg:text-sm">{v.category[0]}</small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => window.open('https://blog.naver.com/wolfnfox114')}
                    className="absolute left-0 bottom-0 w-full h-28 sm:h-64"
                ></div>
            </div>
        </div>
    );
};

const parseString = require('xml2js').parseString;

const ParseData = ({ data }: { data: any[] }) => {
    const dataArr: { title: any; link: any; description: any; pubDate: any; category: any }[] = [];

    data.length > 0 &&
        parseString(data, (e: any, data: any) => {
            // eslint-disable-next-line
            data.rss.channel[0].item.map((v: any, i: any) => {
                i < 10 &&
                    dataArr.push({
                        title: v.title,
                        link: v.link,
                        description: v.description,
                        pubDate: v.pubDate,
                        category: v.category,
                    });
            });
        });

    return dataArr;
};

export default Blog;
