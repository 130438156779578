const Partners = () => {
    const logoList = [
        '/images/part1.png',
        '/images/part2.png',
        '/images/part3.png',
        '/images/part4.png',
        '/images/part5.png',
        '/images/part6.png',
        '/images/part7.png',
        '/images/part8.svg',
        '/images/part9.png',
        '/images/part10.png',
        '/images/part11.png',
        '/images/part12.png',
        '/images/part13.png',
        '/images/part14.png',
        '/images/part15.png',
        '/images/part16.png',
        '/images/part17.svg',
        '/images/part18.png',
        '/images/part19.png',
        '/images/part20.png',
    ];
    return (
        <div
            id="partners"
            className="relative w-full h-full px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto  py-14 lg:py-28"
        >
            <div id="title" className="relative w-full h-fit text-center mb-5">
                <div className="flex justify-center items-center gap-2 lg:gap-5 mb-2">
                    <div className="relative w-60 md:w-80 h-fit">
                        <img
                            className="!relative w-full h-auto object-contain"
                            alt="logo"
                            src="/images/logo.png"
                            width={454}
                            height={89}
                        />
                    </div>
                    <h2 className="leading-none font-normal sebang"> 파트너스</h2>
                </div>
                <p className="text-light lg:text-xl">- PARTNERS -</p>
            </div>
            <div className="relative w-full h-fit grid grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-5">
                {logoList.map((v, i) => (
                    <div
                        key={i}
                        className={
                            i > 14
                                ? 'hidden lg:block relative w-full h-full border px-3 py-6'
                                : 'relative w-full h-full border px-3 py-6'
                        }
                    >
                        <img
                            className="!relative w-auto max-w-20 h-8 lg:max-w-32 lg:h-14 mx-auto object-contain"
                            alt="logo"
                            src={v}
                            width={500}
                            height={500}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Partners;
