import axios from 'axios';
import { toast } from 'react-toastify';

export const quickContact = async ({ name, phone, address }: { name: string; phone: string; address: string }) => {
    await axios
        .post('/api/contact/quick', {
            name: name,
            phone: phone,
            address: address,
            path: sessionStorage.getItem('path'),
            categoryId: 1,
        })
        .then((res) => {
            if (res.data.result === '0000') {
                toast.success('문의 등록이 완료되었습니다.', {
                    onClose: () => {
                        window.location.href = '/transform';
                    },
                });
            }
        })
        .catch((e) => toast.error(e));
};

export const contact = async ({
    name,
    phone,
    address,
    service,
    contents,
    categoryId,
}: {
    name: string;
    phone: string;
    address: string;
    service: string;
    contents: string;
    categoryId: number;
}) => {
    await axios
        .post('/api/contact', {
            name: name,
            phone: phone,
            address: address,
            service: service,
            contents: contents,
            path: sessionStorage.getItem('path'),
            categoryId: categoryId,
        })
        .then((res) => {
            if (res.data.result === '0000') {
                toast.success('문의 등록이 완료되었습니다.', {
                    onClose: () => {
                        window.location.href = '/transform';
                    },
                });
            }
        })
        .catch((e) => toast.error(e));
};

export const contactList = async (setData: any) => {
    await axios
        .get('/api/contact?page=0&size=10')
        .then((res) => {
            if (res.data.result === '0000') {
                setData(res.data.data.content);
            }
        })
        .catch((e) => toast.error(e));
};
