import axios from 'axios';
import { useEffect, useState } from 'react';

import About from 'components/home/About';
import Banner from 'components/home/Banner';
import Blog from 'components/home/Blog';
import Service from 'components/home/Service';
import InquiryList from 'components/home/InquiryList';
import Contact from 'components/home/Contact';
import Partners from 'components/home/Partners';

const Home = () => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        axios.get('/api/blog').then((res) => setData(res.data.data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Banner />
            <About />
            <Service />
            <Blog data={data} />
            <InquiryList />
            <Contact />
            <Partners />
        </div>
    );
};

export default Home;
