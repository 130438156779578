const MBottomQuick = () => {
    return (
        <div className="grid grid-cols-4 lg:hidden fixed right-0 bottom-0 left-0 w-full h-20 z-40">
            <div
                onClick={() => window.open('https://open.kakao.com/o/sQfrgBAg')}
                className="relative w-full h-20 bg-[#f7e500]"
            >
                <img
                    className=" relative w-auto h-14 object-contain mx-auto"
                    src="/images/icon_kakao.png"
                    alt="kakao"
                />
                <p className="leading-none text-center text-sm">1:1 카톡상담</p>
            </div>
            <div
                onClick={() => window.open('https://talk.naver.com/ct/w5u0g5')}
                className="relative w-full h-20 bg-[#00c53a] text-white"
            >
                <img className=" relative w-auto h-14 object-contain mx-auto" src="/images/icon_talk.png" alt="talk" />
                <p className="leading-none text-center text-sm">1:1 톡톡상담</p>
            </div>
            <div
                onClick={() => window.open('https://blog.naver.com/wolfnfox114')}
                className="relative w-full h-20 p-2 bg-[#30b14a] text-white"
            >
                <img
                    className=" relative w-auto h-10 object-contain mx-auto mb-2"
                    src="/images/icon_blog2.png"
                    alt="blog"
                />
                <p className="leading-none text-center text-sm">블로그 리뷰</p>
            </div>
            <div
                onClick={() => {
                    let menuHeight = document.querySelector<HTMLDivElement>('#header')!.offsetHeight;

                    var location = document.querySelector<HTMLDivElement>('#contact')!.offsetTop;

                    window &&
                        window.scrollTo({
                            top: location - menuHeight,
                            behavior: 'smooth',
                        });
                }}
                className="relative w-full h-auto bg-gradient-to-t to-[var(--light-color)] from-[var(--main-color)] "
            >
                <img
                    className=" relative w-auto h-20 object-contain mx-auto"
                    src="/images/icon_contact.png"
                    alt="contact"
                />
            </div>
        </div>
    );
};

export default MBottomQuick;
