import { Outlet, useLocation } from 'react-router-dom';

import MHeader from './header/MHeader';
import RightQuick, { BlogBtn } from './quick/RightQuick';
import BottomQuick from './quick/BottomQuick';
import MFooter from './footer/MFooter';
import MBottomQuick from './quick/MBottomQuick';
import AMHeader from './header/AMHeader';
import AHeader from './header/AHeader';

const Layout = () => {
    const { pathname } = useLocation();

    console.log(pathname);

    if (pathname.includes('login')) {
        return (
            <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
                <Outlet />
            </div>
        );
    }
    if (pathname.includes('admin')) {
        // if (!sessionStorage.getItem('email')) {
        //     window.location.href = '/admin/login';
        //     return <></>;
        // }
        return (
            <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
                <AMHeader />
                <AHeader />
                <div className="relative w-full h-full py-32 lg:py-36 px-2 lg:pl-72 lg:pr-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] ">
                    <Outlet />
                </div>
            </div>
        );
    }
    return (
        <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
            <MHeader />
            <Outlet />
            <RightQuick />
            <BlogBtn />
            <MFooter />
            <BottomQuick />
            <MBottomQuick />
        </div>
    );
};

export default Layout;
