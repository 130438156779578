import { IconLayoutDashboard } from '@tabler/icons-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADMIN_NAV_LIST } from './AHeader';
import { logout } from 'api/login';

const AMHeader = () => {
    const [on, setOn] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <div className="lg:hidden fixed left-0 top-0 w-full h-fit py-5 px-2 bg-[var(--gray-color)] flex justify-between items-center z-40">
                <div onClick={() => setOn(true)}>
                    <img alt="burger" src={process.env.PUBLIC_URL + '/icons/burger.svg'} width={25} height={25} />
                </div>
                <div className="">
                    <img
                        className="!relative w-auto h-full max-h-10"
                        src={process.env.PUBLIC_URL + '/images/logo.png'}
                        alt="logo"
                    />
                </div>
                <div></div>
            </div>
            <div
                className={
                    on
                        ? ' fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 z-50'
                        : 'hidden fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 z-50'
                }
                onClick={() => setOn(false)}
            ></div>
            <div
                className={
                    on
                        ? 'fixed left-0 lg:left-0 top-0 min-w-[270px] w-[270px] h-full min-h-screen bg-white text-[#5A6A85] border-r transition-all duration-200 z-50'
                        : 'fixed -left-full lg:left-0 top-0 min-w-[270px] w-[270px] h-full min-h-screen bg-white text-[#5A6A85] border-r transition-all duration-200 z-50'
                }
            >
                <div className="px-6">
                    <div
                        onClick={() => {
                            setOn(false);
                            navigate('/admin/dashboard');
                        }}
                        className="relative w-auto max-h-10 mt-10 overflow-hidden"
                    >
                        <div>
                            <img
                                className="!relative w-auto h-full max-h-10"
                                alt="logo"
                                src={process.env.PUBLIC_URL + '/images/logo.png'}
                            />
                        </div>
                    </div>
                    <div className="relative mt-12">
                        <small className="text-xs px-2">HOME</small>
                        <div
                            onClick={() => {
                                setOn(false);
                                navigate('/admin/dashboard');
                            }}
                            className={
                                pathname === '/admin/dashboard'
                                    ? 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 bg-[var(--point-color)] text-white rounded-xl transition-all duration-200'
                                    : 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 hover:bg-[var(--point-color)] hover:text-white rounded-xl transition-all duration-200 cursor-pointer'
                            }
                        >
                            <IconLayoutDashboard size={22} stroke={1.5} />
                            <small className="text-sm">Dashboard</small>
                        </div>
                    </div>
                    <div className="relative my-5">
                        <small className="text-xs px-2">DATA</small>
                        {ADMIN_NAV_LIST.map((v, i) => (
                            <div
                                key={i}
                                onClick={() => {
                                    setOn(false);
                                    navigate(v.pathname);
                                }}
                                className={
                                    pathname.includes(v.pathname)
                                        ? 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 bg-[var(--point-color)] text-white rounded-xl transition-all duration-200'
                                        : 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 hover:bg-[var(--point-color)] hover:text-white rounded-xl transition-all duration-200 cursor-pointer'
                                }
                            >
                                <v.icon size={22} stroke={1.5} />
                                <small className="text-sm">{v.title}</small>
                            </div>
                        ))}
                    </div>

                    <div
                        onClick={logout}
                        className=" absolute bottom-0 left-0 w-full h-fit py-2 border-t text-center hover:bg-[var(--gray-color)] cursor-pointer"
                    >
                        <small className="text-sm">Logout</small>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AMHeader;
