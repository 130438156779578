import { contact } from 'api/contact';
import { useState } from 'react';

const Contact = () => {
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [service, setService] = useState<string>('');
    const [contents, setContents] = useState<string>('');
    const categoryId = 3;

    const [setting, setSetting] = useState(false);

    return (
        <div id="contact" className="relative w-full h-full pt-14 pb-24 lg:py-28 bg-[var(--light-color)]">
            <div className="relative w-full h-full px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto ">
                <div id="title" className="relative w-full h-fit text-center mb-5 lg:mb-10 text-white">
                    <div className="flex justify-start items-center gap-2 mb-2">
                        <div className="relative w-52 md:w-80 h-fit">
                            <img
                                className="!relative w-full h-auto object-contain"
                                alt="logo"
                                src="/images/white_logo.png"
                                width={454}
                                height={89}
                            />
                        </div>
                        <div className="lg:flex items-end gap-2">
                            <h2 className="leading-none sebang">상세&nbsp;견적상담</h2>
                            <p className="hidden lg:inline-flex lg:text-xl">
                                문자로&nbsp;현장&nbsp;사진을&nbsp;보내주시면
                                빠른&nbsp;견적산출에&nbsp;도움이&nbsp;됩니다.
                            </p>
                        </div>
                    </div>
                    <p className="block lg:hidden">
                        문자로&nbsp;현장&nbsp;사진을&nbsp;보내주시면 빠른&nbsp;견적산출에&nbsp;도움이&nbsp;됩니다.
                    </p>
                </div>
                <div className="relative w-full h-fit bg-white p-5 rounded-sm">
                    <div className=" relative w-full h-full grid grid-cols-3 gap-5">
                        <div className="col-span-3 relative w-full grid grid-cols-2 gap-5">
                            <div>
                                <label htmlFor="name3">이름</label>
                                <input
                                    type="text"
                                    id="name3"
                                    name="name3"
                                    className="!h-12"
                                    placeholder="이름을 입력해주세요"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="phone3">연락처</label>
                                <input
                                    type="text"
                                    id="phone3"
                                    name="phone3"
                                    className="!h-12"
                                    placeholder="연락처를 입력해주세요"
                                    onChange={(e) =>
                                        setPhone(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-span-3 relative w-full grid grid-cols-2 gap-5">
                            <div>
                                <label htmlFor="address3">주소</label>
                                <input
                                    type="text"
                                    id="address3"
                                    name="address3"
                                    className="!h-12"
                                    placeholder="주소를 입력해주세요"
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="service3">희망 서비스</label>
                                <select
                                    name="service3"
                                    id="service3"
                                    className="!h-12"
                                    onChange={(e) => setService(e.target.value)}
                                >
                                    <option value="유품정리">유품정리</option>
                                    <option value="쓰레기집청소">쓰레기집 청소</option>
                                    <option value="빈집정리">빈집정리</option>
                                    <option value="고독사청소">고독사 청소</option>
                                    <option value="특수청소">특수청소</option>
                                    <option value="폐기물처리">폐기물 처리</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-span-3 lg:col-span-2 relative w-full">
                            <div>
                                <label htmlFor="contents2">문의 내용</label>
                                <textarea
                                    id="contents2"
                                    name="contents2"
                                    placeholder="내용을 입력해주세요"
                                    className="h-40 lg:!h-56"
                                    onChange={(e) => setContents(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-span-3 lg:col-span-1 lg:pt-7 w-full relative">
                            <p>
                                문자 접수 후 <span className="text-[#ff0000]">3시간 이내에</span> 전화드릴 예정입니다.
                            </p>
                            <div className="relative w-fit h-fit mx-auto lg:mx-0">
                                <button
                                    type="button"
                                    disabled={setting}
                                    onClick={() => {
                                        setSetting(true);
                                        contact({
                                            name,
                                            phone,
                                            address,
                                            service,
                                            contents,
                                            categoryId,
                                        });
                                    }}
                                    className=" relative w-fit h-fit bg-[#595959] text-white px-10 py-1 my-3 rounded-full"
                                >
                                    <h4>전송하기</h4>
                                </button>
                            </div>
                            <div>
                                <span className="flex items-center">
                                    <input type="checkbox" name="privacy3" id="privacy3" defaultChecked readOnly />
                                    &nbsp;
                                    <label htmlFor="privacy3" className="text-xs">
                                        [필수]&nbsp;개인정보&nbsp;수집&nbsp;이용&nbsp;제공&nbsp;동의
                                    </label>
                                </span>
                                <span className="flex items-center">
                                    <input type="checkbox" name="privacy4" id="privacy4" defaultChecked readOnly />
                                    &nbsp;
                                    <label htmlFor="privacy4" className="text-xs">
                                        [필수]&nbsp;이름,&nbsp;연락처,&nbsp;주소에&nbsp;대한&nbsp;정보를
                                        모두&nbsp;확인하였습니다.
                                    </label>
                                </span>
                            </div>
                            <div className=" relative mt-3 w-full h-fit">
                                <img src="/images/contactNum.png" alt="num" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" absolute bottom-0 right-0 w-full h-fit max-w-52 lg:max-w-60 xl:max-w-64">
                <img src="/images/contact_icon.png" alt="main" />
            </div>
        </div>
    );
};

export default Contact;
