import React from 'react';

const About = () => {
    const aboutList = [
        { img: '/images/about1.png', txt1: '출장비 없는 출장견적!', txt2: '지금 바로 찾아갑니다' },
        { img: '/images/about2.png', txt1: '고객만족 120% 달성!', txt2: '성실히 일하겠습니다' },
        { img: '/images/about3.png', txt1: '어떤 현장도 해결가능!', txt2: '어떤 현장도 자신있습니다' },
        { img: '/images/about4.png', txt1: '365일 24시간 상담!', txt2: '언제든지 연락주세요' },
    ];
    return (
        <div
            id="about"
            className="relative w-full h-full px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto py-14 lg:py-28 xl:pb-36"
        >
            <div className="absolute bottom-3 lg:bottom-14 right-0 w-full h-fit px-[calc((100%-var(--container))/2)]">
                <div className="relative w-full h-fit max-w-[950px] ml-auto">
                    <img alt="bg" src="/images/aboutBg.png" />
                </div>
            </div>
            <div id="title" className="relative w-full h-fit text-center mb-5 lg:mb-10">
                <div className="md:flex justify-center items-center mb-2">
                    <div className="relative w-60 md:w-80 mb-3 lg:mb-0 h-fit mx-auto md:mx-0">
                        <img
                            className="!relative w-full h-auto object-contain"
                            alt="logo"
                            src="/images/logo.png"
                            width={454}
                            height={89}
                        />
                    </div>
                    <h2 className="leading-none font-normal sebang">
                        <span className="hidden lg:inline-flex">이</span>&nbsp;고객님께&nbsp;공유드리는{' '}
                        <span className="text-[var(--point-color)] sebang-bold">4가지&nbsp;장점</span>
                    </h2>
                </div>
                <p className=" lg:text-xl">
                    처음처럼 모두치움은 아래와 같은 <span className="text-[var(--point-color)]">4가지 장점</span>으로
                    <span className="text-[var(--point-color)]">고객님에게 큰 도움</span>이 되어드리겠습니다.
                </p>
            </div>
            <div className="relative w-full h-fit max-w-screen-xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 lg:gap-8 ">
                {aboutList.map((v, i) => (
                    <div key={i} data-aos="fade-up" className="relative w-full h-fit overflow-hidden">
                        <img
                            className="!relative w-full h-full object-cover hover:scale-110 transition-transform duration-300"
                            alt="img"
                            src={v.img}
                            width={434}
                            height={641}
                        />
                        <div className="absolute bottom-0 left-0 w-full h-fit bg-gradient-to-t to-[var(--light-color)] from-[var(--main-color)] text-white text-center py-3">
                            <div className="leading-tight">
                                <h3 className="!font-bold hidden lg:block">{v.txt1}</h3>
                                <h4 className="font-normal hidden lg:block">{v.txt2}</h4>
                                <p className="!font-bold block lg:hidden">{v.txt1}</p>
                                <small className="font-normal block lg:hidden">{v.txt2}</small>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default About;
