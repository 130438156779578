const Service = () => {
    const serviceList = [
        {
            img: '/images/service1.png',
            txt: '유품정리 전문',
        },
        {
            img: '/images/service2.png',
            txt: '쓰레기집 청소',
        },
        {
            img: '/images/service3.png',
            txt: '빈집정리 전문',
        },
        {
            img: '/images/service4.png',
            txt: '고독사 청소',
        },
        {
            img: '/images/service5.png',
            txt: '특수청소 전문',
        },
        {
            img: '/images/service6.png',
            txt: '폐기물 처리',
        },
    ];
    return (
        <div
            id="service"
            className="relative w-full h-full px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto py-14 lg:py-28 xl:pb-36"
        >
            <div id="title" className="relative w-full h-fit text-center mb-5 lg:mb-10">
                <div className="md:flex justify-center items-center gap-2 mb-2">
                    <div className="relative w-60 md:w-80 mb-3 lg:mb-0 h-fit mx-auto md:mx-0">
                        <img
                            className="!relative w-full h-auto object-contain"
                            alt="logo"
                            src="/images/logo.png"
                            width={454}
                            height={89}
                        />
                    </div>
                    <h2 className="leading-none font-normal sebang">서비스 목록</h2>
                </div>
                <p className=" lg:text-xl">어떤 현장이라도 처음처럼 깔끔하게 해결해드립니다</p>
            </div>
            <div className="relative w-full h-fit max-w-screen-xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-5">
                {serviceList.map((v, i) => (
                    <div
                        key={i}
                        data-aos="fade-up"
                        className="relative w-full h-full bg-white p-3 border shadow-lg rounded-md"
                    >
                        <div className="relative w-full h-full overflow-hidden">
                            <img
                                className="!relative w-full h-full object-cover hover:scale-110 transition-transform duration-300"
                                alt="service"
                                src={v.img}
                                width={328}
                                height={355}
                            />
                        </div>
                        <div className="absolute bottom-0 left-0 w-full h-fit bg-white text-center text-light pt-3 pb-2">
                            <p className="lg:text-3xl">{v.txt}</p>
                        </div>
                    </div>
                ))}
                <div
                    onClick={() => (window.location.href = 'tel:010-9344-8272')}
                    className="col-span-2 relative w-full h-fit mt-auto p-5 lg:pr-10"
                >
                    <img
                        className=" relative w-full h-auto object-contain"
                        src="/images/service_num.png"
                        alt="number"
                    />
                </div>
            </div>
        </div>
    );
};

export default Service;
