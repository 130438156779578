import { contactList } from 'api/contact';
import axios from 'axios';
import { dateFormat } from 'const/date';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();

    const [total, setTotal] = useState(0);
    const [contact, setContact] = useState<
        {
            id: number;
            name: string;
            phone: string;
            email: string;
            type: string;
            zipcode: string;
            address: string;
            adrDetail: string;
            coreAndMm: string;
            coreType: string;
            workNeed: string;
            contents: string;
            created: Date;
        }[]
    >(Array);

    useEffect(() => {
        axios.get('/api/contact/count').then((res) => {
            if (res.data.result === '0000') {
                setTotal(res.data.data);
            }
        });
    }, []);

    useEffect(() => {
        contactList(setContact);
        // boardList(setPortfolio);
    }, []);
    return (
        <div>
            <div className="flex gap-2">
                <small className="bold text-sm">TODAY</small>
                <small className="text-sm">{dateFormat(Date.now())}</small>
            </div>
            <div className="relative w-full h-fit bg-white border shadow-md py-5 px-4 rounded-lg cursor-pointer my-3">
                <small className="text-base">문의글 등록 수</small>
                <div className="text-center">
                    <h5>총 {total}개</h5>
                </div>
            </div>

            <div className=" relative w-full h-full grid gap-5">
                <Link to={'/admin/inquiry'} className=" relative w-full h-full">
                    <div className="relative w-full h-full bg-white border shadow-md py-5 px-4 rounded-lg cursor-pointer my-3">
                        <div className="mb-3 lg:mb-5">
                            <small className="text-base">온라인문의</small>
                        </div>
                        {contact.length > 0 &&
                            contact.map((v, i) => (
                                <div
                                    key={i}
                                    onClick={() => navigate(`/admin/inquiry/${v.id}`)}
                                    className=" relative w-full h-fit grid grid-cols-9 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
                                >
                                    <p>{v.id}</p>
                                    <p className="col-span-2">{v.name}</p>
                                    <p className="col-span-3">{v.phone}</p>
                                    <p className="col-span-3">{dateFormat(v.created)}</p>
                                </div>
                            ))}
                    </div>
                </Link>
                {/* <Link to={'/admin/portfolio'} className=" relative w-full h-full">
                    <div className="relative w-full h-full bg-white border shadow-md py-5 px-4 rounded-lg cursor-pointer my-3">
                        <div className="mb-3 lg:mb-5">
                            <small className="text-base">주요실적</small>
                        </div>
                        {portfolio.length > 0 &&
                            portfolio.map((v, i) => (
                                <div
                                    key={i}
                                    className=" relative w-full h-fit grid grid-cols-7 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
                                >
                                    <p onClick={() => navigate(`/admin/portfolio/${v.id}`)}>{v.id}</p>
                                    <p onClick={() => navigate(`/admin/portfolio/${v.id}`)} className="col-span-3">
                                        {v.title}
                                    </p>
                                    <p onClick={() => navigate(`/admin/portfolio/${v.id}`)}>{v.clickCount}</p>
                                    <p onClick={() => navigate(`/admin/portfolio/${v.id}`)} className="col-span-2">
                                        {dateFormat(v.created)}
                                    </p>
                                </div>
                            ))}
                    </div>
                </Link> */}
            </div>
        </div>
    );
};

export default Dashboard;
