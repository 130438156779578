import { quickContact } from 'api/contact';
import React, { useState } from 'react';

const BottomQuick = () => {
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const [loading, setLoading] = useState(false);
    return (
        <div className="hidden xl:block fixed left-0 right-0 bottom-0 w-full h-fit bg-gradient-to-t to-[var(--light-color)] from-[var(--main-color)] z-40">
            <div className=" relative w-full h-full max-h-20 flex justify-between items-center gap-5 pt-2 px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto">
                <div>
                    <img src="/images/white_logo.png" alt="logo" />
                </div>
                <div>
                    <img src="/images/icon_favicon.png" alt="favicon" />
                </div>
                <div className=" relative w-full h-full">
                    <div className=" relative grid grid-cols-3 gap-2">
                        <div className="relative w-full h-fit">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                className="!h-8 !pl-12 text-xs"
                                placeholder="이름을 입력해주세요"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label className=" absolute left-2 top-0 h-full flex gap-1 items-center" htmlFor="name">
                                <p className="leading-none text-[var(--main-color)]">이름</p>
                                <p className="text-gray-300">|</p>
                            </label>
                        </div>
                        <div className="relative w-full h-fit">
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="!h-8 !pl-16 text-xs"
                                placeholder="연락처를 입력해주세요"
                                onChange={(e) =>
                                    setPhone(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
                                }
                            />
                            <label className=" absolute left-2 top-0 h-full flex gap-1 items-center" htmlFor="phone">
                                <p className="leading-none text-[var(--main-color)]">연락처</p>
                                <p className="text-gray-300">|</p>
                            </label>
                        </div>
                        <div className="relative w-full h-fit">
                            <input
                                type="text"
                                name="address"
                                id="address"
                                className="!h-8 !pl-12 text-xs"
                                placeholder="주소를 입력해주세요"
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <label className=" absolute left-2 top-0 h-full flex gap-1 items-center" htmlFor="address">
                                <p className="leading-none text-[var(--main-color)]">주소</p>
                                <p className="text-gray-300">|</p>
                            </label>
                        </div>
                    </div>
                    <div className=" relative flex items-center gap-5 text-white">
                        <span>
                            <input type="checkbox" name="privacy1" id="privacy1" defaultChecked readOnly />
                            &nbsp;
                            <label htmlFor="privacy1" className="text-xs">
                                [필수] 개인정보 수집 이용 제공 동의
                            </label>
                        </span>
                        <span>
                            <input type="checkbox" name="privacy2" id="privacy2" defaultChecked readOnly />
                            &nbsp;
                            <label htmlFor="privacy2" className="text-xs">
                                [필수] 이름, 연락처, 주소에 대한 정보를 모두 확인하였습니다.
                            </label>
                        </span>
                    </div>
                </div>
                <div className=" relative w-auto h-full">
                    <button
                        type="button"
                        disabled={loading}
                        onClick={() => {
                            setLoading(true);
                            quickContact({ name, phone, address });
                        }}
                        className=" relative w-fit h-fit mb-2 bg-white text-[var(--main-color)] py-2 px-5 rounded-sm hover:bg-[#fd9f28] hover:text-white transition-colors duration-300"
                    >
                        <p>접수&nbsp;신청하기</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BottomQuick;
